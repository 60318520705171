import React from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import AboutMe from './components/about/About';
import Gallery from './components/gallery/Gallery';
import Price from './components/pricelist/Pricelist';
import Social from './components/social/Social';
import Contact from './components/contact/Contact';
import logo from './logowatermark.png';
import './App.css';

export default function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <header className="App-header">
          <Link to="/">
            <img src={logo} className="App-logo" alt="logo" />
          </Link>
          <Social />
          <Switch>
            <Route path="/omnie">
              <AboutMe />
            </Route>
            <Route path="/galeria">
              <Gallery />
            </Route>
            <Route path="/cennik">
              <Price />
            </Route>
            <Route path="/kontakt">
              <Contact />
            </Route>
          </Switch>
        </header>
        <div className="footer">
          <p>&copy; copyright 2020 marcin delert. all rights reserved</p>
        </div>
      </div>
    </BrowserRouter>
  );
}
