import React from 'react';
import './Pricelist.css';

export default function Price(){
        return (
            <div id="price">
                <div className="price-container">
                    <div className="price-container-text">
                        <h3>Cennik</h3>
                        <p>Makijaż permanentny brwi wraz z korektą po miesiącu - 600</p>
                        <p>Makijaż permanentny ust wraz z korektą po miesiącu - 600</p>
                        <p>Odświeżenie makijażu permanentnego po min roku wykonanego u mnie - 300</p>
                        <p>Architektura brwi - Henna Pudrowa - 70</p>
                        <p>Regulacja brwi - 20</p>
                        <p>Przedłużanie rzęs 1:1 - 120</p>
                        <p>Uzupełnienie 1:1 - 100</p>
                        <p>Przedłużanie i zagęszczanie rzęs 2D - 130</p>
                        <p>Uzupełnienie 2D - 110</p>
                        <p>Przedłużanie i zagęszczanie rzęs 2/3D - 150</p>
                        <p>Uzupełnienie 2/3D - 130</p>
                        <p>Przedłużanie i zagęszczanie rzęs 3D - 160</p>
                        <p>Uzupełnienie 3D- 130</p>  
                        <p>Przedłużanie i zagęszczanie rzęs 4/6D - 170</p>
                        <p>Uzupełnienie 4/6D- 150</p>
                        <p>Przedłużanie i zagęszczanie rzęs 6/8D - 190</p>
                        <p>Uzupełnienie 6/8D- 160</p>
                        <p>Zdjęcie rzęs - 30</p>
                        <p>Henna rzęs - 15</p>
                        <p>Laminacja rzęs - 100</p>
                        <p>Small Tattoo - 150</p>
                    </div>
                </div>
            </div>
        );
    }