import React from "react";
import "./About.css";

export default function AboutMe() {
  return (
    <div id="about">
      <div className="about-container">
        <div className="about-container-text">
          <p>
            Nazywam się Paulina Delert i zajmuje się mikropigmentacją
            estetyczną. Ukończyłam studia magisterskie na Uniwersytecie
            Warszawskim. Z wykształcenia jestem szkoleniowcem i trenerem
            kompetencji miękkich, jednak od zawsze interesowała mnie branża
            beauty. Ukończyłam mnóstwo szkoleń z zakresu makijażu permanentnego
            brwi i ust w znanych instytutach kosmetologicznych. Dodatkowo
            przeszłam szereg kursów i warsztatów związanych ze stylizacją rzęs u
            wielu świetnych szkoleniowców m.in. zwyciężczyni kilku
            międzynarodowych konkursów w Europie.
            Ponadto zajmuję się wykonywaniem micro tatuaży tzw. "Small Tattoo", charakteryzujących się delikatnym konturem.
            <br/>
            Zajmuję się również szkoleniami - jeżeli chciałabyś pracować w branży kosmetycznej to zapraszam do kontaktu.
          </p>
          <p>Zapraszam serdecznie </p>
        </div>
      </div>
    </div>
  );
}
