import React from 'react';
import './Contact.css';

export default function Contact() {
    return (
        <div id="contact">
            <div className="contact-container">
                <div className='contact_info-column'>
                    <h3>Kontakt</h3>
                    <p>ul.Żegańska 12 lokal 5</p>
                    <p>04-713 Warszawa</p>
                    <p>796 726 596</p>
                    <p>poladelert@gmail.com</p>
                    <br />
                    <h3>Godziny Otwarcia</h3>
                    <p>Poniedziałek - Piątek: 9.00 - 20.00</p>
                    <p>Sobota: 9.00 - 18.00</p>
                </div>
            </div>
        </div>
    );
}

