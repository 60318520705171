import React, {useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

export default function Navbar() {
    const ref = useRef(null);
    const [open, setOpen] = useState(true);
    const openNavbar = () => setOpen(!open);
    const closeNavbar = () => setOpen(true);

    return (
        <div className="navbar-container" >
            {/* https://codepen.io/mutedblues/pen/MmPNPG?editors=1100 */}
            <header className="header" ref={ref}>
                <input className="menu-btn" type="checkbox" id="menu-btn" />
                <label className="menu-icon" for="menu-btn" onClick={openNavbar} >
                     <span className={`navicon ${open ? "" : "open"}`}></span>
                     <span className={`navicon ${open ? "" : "open"}`}></span>
                     <span className={`navicon ${open ? "" : "open"}`}></span>
                     </label>
                    <ul className={`menu ${open ? " " : "active"}`}>
                        <li><Link onClick={closeNavbar} to='/omnie'>O mnie</Link></li>
                        <li><Link onClick={closeNavbar} to='/galeria'>Galeria</Link></li>
                        <li><Link onClick={closeNavbar} to='/cennik'>Cennik</Link></li>
                        <li><Link onClick={closeNavbar} to='/kontakt'>Kontakt</Link></li>
                    </ul>
            </header>
        </div>
    );
}

