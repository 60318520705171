//clean id: 3, 9, 25, 26, 

export const photolist = [
    {
        id: "photo",
        photo: [
            {
                id: 1,
                src: require("../../photo/1.png")
            },
              {
                id: 5,
                src: require("../../photo/5.png")
            },
            {
                id: 8,
                src: require("../../photo/8.png")
            },
              {
                id: 11,
                src: require("../../photo/11.jpg")
            },
            {
                id: 14,
                src: require("../../photo/14.jpg")
            },
              {
                id: 19,
                src: require("../../photo/19.png")
            },
            {
                id: 20,
                src: require("../../photo/20.png")
            },
              {
                id: 21,
                src: require("../../photo/21.png")
            },
            {
                id: 22,
                src: require("../../photo/22.png")
            },
              {
                id: 28,
                src: require("../../photo/28.png")
            },
            {
                id: 29,
                src: require("../../photo/29.png")
            },
              {
                id: 31,
                src: require("../../photo/31.png")
            },
            {
                id: 41,
                src: require("../../photo/41.png")
            },
            {
                id: 44,
                src: require("../../photo/44.png")
            },
              {
                id: 45,
                src: require("../../photo/45.png")
            },
        ],
        photo2: [
            {
                id: 2,
                src: require("../../photo/2.png")
            },  
               {
                id: 7,
                src: require("../../photo/7.png")
            },
            {
                id: 10,
                src: require("../../photo/10.jpg")
            },  
               {
                id: 13,
                src: require("../../photo/13.png")
            },
            {
                id: 15,
                src: require("../../photo/15.jpg")
            },  
               {
                id: 23,
                src: require("../../photo/23.png")
            },
            {
                id: 24,
                src: require("../../photo/24.jpg")
            },  
               {
                id: 32,
                src: require("../../photo/32.png")
            },
            {
                id: 33,
                src: require("../../photo/33.png")
            },  
               {
                id: 34,
                src: require("../../photo/34.png")
            },
            {
                id: 35,
                src: require("../../photo/35.png")
            },  
               {
                id: 40,
                src: require("../../photo/40.png")
            },
            {
                id: 46,
                src: require("../../photo/46.png")
            },
            {
                id: 47,
                src: require("../../photo/47.jpg")
            },  
               {
                id: 48,
                src: require("../../photo/48.png")
            },
            {
                id: 49,
                src: require("../../photo/49.png")
            },  
        ],
        photo3: [
            {
                id: 4,
                src: require("../../photo/4.png")
            },  
               {
                id: 6,
                src: require("../../photo/6.png")
            },
            {
                id: 12,
                src: require("../../photo/12.png")
            },  
               {
                id: 16,
                src: require("../../photo/16.jpg")
            },
            {
                id: 17,
                src: require("../../photo/17.png")
            },  
               {
                id: 18,
                src: require("../../photo/18.png")
            },
            {
                id: 27,
                src: require("../../photo/27.png")
            },  
               {
                id: 36,
                src: require("../../photo/36.png")
            },
            {
                id: 37,
                src: require("../../photo/37.png")
            },  
               {
                id: 38,
                src: require("../../photo/38.png")
            },
            {
                id: 42,
                src: require("../../photo/42.png")
            },  
               {
                id: 43,
                src: require("../../photo/43.png")
            },
            {
                id: 50,
                src: require("../../photo/50.png")
            },
            {
                id: 51,
                src: require("../../photo/51.png")
            },  
               {
                id: 52,
                src: require("../../photo/52.png")
            },
            {
                id: 53,
                src: require("../../photo/53.png")
            },   
        ],
    }
];
