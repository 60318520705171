import React from "react";
import ReactMediumImg from "react-medium-zoom";
import { photolist } from "../gallery/Photo";
import "./Gallery.css";

export default function Gallery() {
  const photoIndex = photolist.findIndex((obj) => obj.id === "photo");
  return (
    // Grid gallery
    // https://www.w3schools.com/howto/howto_css_image_grid_responsive.asp
    // https://github.com/shahen94/react-medium-zoom
    <div id="gallery">
      <br />
      <br />
      <br />
      <div className="gallery-photos">
        <div className="gallery row">
          <div className="gallery column">
            {photolist[photoIndex].photo.map((element, index) => (
              <ReactMediumImg
                key={index}
                src={element.src}
                onOpen={() => console.log("Image Open")}
                onClosed={() => console.log("Image closed")}
              />
            ))}
          </div>

          <div className="gallery column">
            {photolist[photoIndex].photo2.map((element, index) => (
              <ReactMediumImg
                key={index}
                src={element.src}
                onOpen={() => console.log("Image Open")}
                onClosed={() => console.log("Image closed")}
              />
            ))}
          </div>

          <div className="gallery column">
            {photolist[photoIndex].photo3.map((element, index) => (
              <ReactMediumImg
                key={index}
                src={element.src}
                onOpen={() => console.log("Image Open")}
                onClosed={() => console.log("Image closed")}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
