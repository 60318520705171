import React from 'react';
import './Social.css';
import logobooksy from './logobooksy.jpg';

export default function Social() {
        return ( 
            <div className="social_media">
                <a href="https://www.facebook.com/makijazpermanentnydelert" className="sociallinks" target="blank"><i className ="fa fa-2x fa-facebook-square"></i> </a>
                <a href="https://www.instagram.com/delert_cosmetology/" className="sociallinks" target="blank"><i className ="fa fa-2x fa-instagram"> </i></a> 
                <a href="https://booksy.com/pl-pl/38892_studio-makijazu-permanentnego-i-stylizacji-rzes-pola-delert_brwi-i-rzesy_3_warszawa?fbclid=IwAR2e-eK-Q_wxeZppuvIIGr5fkS8JT59hydAZnn0koFvBb6gXcf17TD-YzoY" className="sociallinks" target="blank"><img src={logobooksy} className ="logobooksy" alt=""/></a>
            </div>
        );
    }


